import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = '123456';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = false;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Munika Nur Tridayanti';
export const GIRL_NAME_SHORT = 'Munika';
export const GIRL_FATHER_NAME = `H. Supono Hadi`;
export const GIRL_MOTHER_NAME = `Hj. Nur Aini`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The Daughter of: <br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari: <br />Bapak ${GIRL_FATHER_NAME} <br /> dan Ibu ${GIRL_MOTHER_NAME}`;

// -> boy section
export const BOY_NAME = 'Muhammad Alif Asshidiqi';
export const BOY_NAME_SHORT = 'Alif';
export const BOY_FATHER_NAME = `H. Bagus Hari Abrianto`;
export const BOY_MOTHER_NAME = `Hj. Henik Sulistyorini`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The Son of:<br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari:<br /> Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`;

// -> bride section
export const BRIDE_HASHTAG = ``;
export const QUOTES = `“Semoga Allah memberkahimu ketika bahagia dan ketika susah, serta mengumpulkan kalian berdua dalam kebaikan.” <br />(HR. Abu Daud: 2130)`;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'alif.asshidiqi';
export const IG_GIRL = 'munikadayanti';
export const IG_FILTER_URL = `https://www.instagram.com/ar/6880293215409918/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/zaanfa2/munika-alif/seo.jpg?updatedAt=1710801813537`;
export const SEO_URL = 'https://invitato.net/akadcintaastridanakbar/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut.`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1714195800;
export const EPOCH_RESEPSI_START = 1714195800;
export const EPOCH_END_EVENT = 1714204800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-04-27');
export const DATE_RESEPSI = new Date('2024-04-27');

export const WEDDING_AKAD_TIME = '07.30 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Kediaman Mempelai Wanita';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Ki Mangunsarkoro No. 42, Degayu, Kota Pekalongan`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_NOTE = ``;
export const WEDDING_AKAD_DAY = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd');
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '12.30 - 15.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Gedung Amanjiba';
export const WEDDING_RESEPSI_LOC_ROAD = 'Jl. Truntum, Klego, Kota Pekalongan';
export const WEDDING_RESEPSI_DRESSCODE = '';
export const WEDDING_RESEPSI_NOTE = ``;
export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd');
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD MMMM YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://alifetimewithmun.com/';
export const BROADCAST_WEDDING_LOCATION = `Gedung Amanjiba, Kota Pekalongan`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = ``;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/dBrw24WcbhxcGkSV6`;
export const GOOGLE_MAPS_ADDRESS = `Gedung Pertemuan AMAN - JIBA`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `If Ever You're in My Arms Again - Peabo Bryson`;
export const SOUND_URL = 'https://youtu.be/qcDvzifP_gk?si=cMOhdnzGX-8dEDVk';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/6Em1CUIDxkU?si=fe30fkTG372JuYmc';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/6Em1CUIDxkU?si=fe30fkTG372JuYmc';
export const PREWEDDING_LINK = 'https://youtu.be/6Em1CUIDxkU?si=fe30fkTG372JuYmc';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/6Em1CUIDxkU?si=fe30fkTG372JuYmc';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
export const URL_INVITATO = 'https://invitato.id';
export const INVITATO_IG_URL = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';